import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import BlogBanner from "../components/blog-banner";
import CtaBanner from "../components/cta-banner";
import Layout from "../components/layout";
import RelatedBlogs from "../components/related-blog";
import blogBottomBg from "../images/blog-bottom-bg.png";
import blogTopBg from "../images/blog-top-bg.png";

const BlogPostTemplate = ({ data: { post, site, allWpPost } }) => {
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${siteUrl}/blog`,
					id: `${siteUrl}/blog`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/blog/${post.slug}`,
					id: `${siteUrl}/blog/${post.slug}`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={post.seoFields?.title || post?.title}
				description={post.seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/blog/${post.slug}`,
					title: `${post.seoFields?.opengraphTitle || post?.title}`,
					description: `${post.seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${post.seoFields?.image?.node.sourceUrl}`,
							width: `${post.seoFields?.image?.node.mediaDetails.width}`,
							height: `${post.seoFields?.image?.node.mediaDetails.height}`,
							alt: `${post.seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>
			<Layout isDefault={false}>
				<BlogBanner
					heading={post.title}
					blogPostFields={post.blogPostFields}
					date={post.date}
				/>
				<section
					className="py-8 blog-default-content"
					style={sectionWrapper(blogTopBg)}
				>
					<div style={sectionWrapperBottom(blogBottomBg)}>
						<Container>
							<Row className="justify-content-center">
								<Col lg={8}>
									<div
										className="text-black"
										dangerouslySetInnerHTML={{
											__html:
												post.blogPostFields.blogPostContentEditor
													.blogPostDefaultContent,
										}}
									></div>
								</Col>
							</Row>
						</Container>
					</div>
				</section>

				<RelatedBlogs
					heading={post.blogPostFields?.relatedBlogs.relatedBlogsHeading}
					btnCta1={post.blogPostFields?.relatedBlogs?.relatedBlogsButton}
					allPostList={allWpPost?.nodes}
					currentPost={post}
				/>
				{post?.blogPostFields?.blogPostCtaWithBackgroundImage && (
					<CtaBanner
						heading={
							post?.blogPostFields?.blogPostCtaWithBackgroundImage
								.blogPostCtaHeading
						}
						content={
							post?.blogPostFields?.blogPostCtaWithBackgroundImage
								.blogPostCtaContent
						}
						btnCta1={
							post?.blogPostFields?.blogPostCtaWithBackgroundImage
								.blogPostCtaButton
						}
						bgImage={
							post?.blogPostFields?.blogPostCtaWithBackgroundImage
								?.blogPostCtaBackgroundImage?.node
						}
						textPosition="center"
						isGreen={false}
					/>
				)}
			</Layout>
		</>
	);
};

export const sectionWrapper = (imgUrl) => ({
	background: `url(${imgUrl}) no-repeat`,
	backgroundPosition: "top right",
	backgroundSize: "35%",
});

export const sectionWrapperBottom = (imgUrl) => ({
	background: `url(${imgUrl}) no-repeat`,
	backgroundPosition: "bottom left",
	backgroundSize: "35%",
});

export default BlogPostTemplate;

export const pageQuery = graphql`
	query ($id: String!) {
		post: wpPost(id: { eq: $id }) {
			id
			slug
			title
			date(formatString: "DD MMMM, YYYY")
			blogPostFields {
				blogPostBanner {
					blogPostBannerHeading

					# blogPostBannerAuthorProfileImage {
					# 	node {
					# 		altText
					# 		sourceUrl
					# 		localFile {
					# 			childImageSharp {
					# 				gatsbyImageData(
					# 					formats: WEBP
					# 					quality: 100
					# 					transformOptions: { cropFocus: CENTER, fit: CONTAIN }
					# 					layout: CONSTRAINED
					# 					placeholder: BLURRED
					# 					width: 56
					# 				)
					# 			}
					# 		}
					# 	}
					# }
					blogPostBannerBackgroundImage {
						node {
							altText
							sourceUrl
						}
					}
					blogPostBannerImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: AVIF
										quality: 30
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: FULL_WIDTH
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				blogPostContentEditor {
					blogPostDefaultContent
				}
				relatedBlogs {
					relatedBlogsHeading
					relatedBlogsButton {
						title
						target
						url
					}
				}
				blogPostCtaWithBackgroundImage {
					blogPostCtaHeading
					blogPostCtaContent
					blogPostCtaButton {
						title
						target
						url
					}
					blogPostCtaBackgroundImage {
						node {
							altText
							sourceUrl
						}
					}
				}
			}
			terms {
				nodes {
					slug
					name
				}
			}
			seoFields {
				title
				opengraphTitle
				opengraphDescription
				metaDescription
				localBusinessSchema
				image {
					node {
						altText
						sourceUrl
						mediaDetails {
							height
							width
						}
					}
				}
			}
		}
		allWpPost(sort: { order: DESC, fields: date }) {
			nodes {
				title
				slug
				excerpt
				date(formatString: "DD MMMM, YYYY")
				featureImage {
					featureImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: AVIF
										quality: 30
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				blogPostFields {
					blogPostBanner {
						category
						blogPostBannerHeading

						# blogPostBannerAuthorProfileImage {
						# 	node {
						# 		altText
						# 		sourceUrl
						# 		localFile {
						# 			childImageSharp {
						# 				gatsbyImageData(
						# 					formats: WEBP
						# 					quality: 100
						# 					transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						# 					layout: CONSTRAINED
						# 					placeholder: BLURRED
						# 					width: 48
						# 				)
						# 			}
						# 		}
						# 	}
						# }
						blogPostBannerBackgroundImage {
							node {
								altText
								sourceUrl
							}
						}
						blogPostBannerImage {
							node {
								altText
								sourceUrl
							}
						}
					}
					blogPostContentEditor {
						blogPostDefaultContent
					}
					relatedBlogs {
						relatedBlogsHeading
						relatedBlogsButton {
							title
							target
							url
						}
					}
					blogPostCtaWithBackgroundImage {
						blogPostCtaHeading
						blogPostCtaContent
						blogPostCtaButton {
							title
							target
							url
						}
						blogPostCtaBackgroundImage {
							node {
								altText
								sourceUrl
							}
						}
					}
				}
				terms {
					nodes {
						slug
						name
						... on WpCategory {
							name
							parentId
							slug
						}
					}
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
