import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const BlogBanner = ({ heading, blogPostFields, date }) => {
	return (
		<section
			className="py-8"
			style={sectionWrapper(
				blogPostFields.blogPostBanner.blogPostBannerBackgroundImage?.node
					.sourceUrl
			)}
		>
			<Container>
				<Row className="align-items-lg-center justify-content-center">
					<Col lg={7} className="mb-4 mb-lg-0 text-start">
						<h2 className="my-auto text-white fs-2">{heading}</h2>
						<div className="d-flex mt-4">
							{/* {blogPostFields.blogPostBanner.blogPostBannerAuthorProfileImage
                ?.node.sourceUrl ? (
                <GatsbyImage
                  image={
                    blogPostFields.blogPostBanner
                      .blogPostBannerAuthorProfileImage?.node?.localFile
                      .childImageSharp.gatsbyImageData
                  }
                  alt={
                    blogPostFields.blogPostBanner
                      .blogPostBannerAuthorProfileImage?.node?.altText
                  }
                  className="rounded-5"
                />
              ) : (
                <AvatarIcon />
              )} */}

							<div>
								{/* <span className="fs-6 text-white">
                  {blogPostFields.blogPostBanner.blogPostBannerAuthorName}
                </span> */}
								<span
									className="d-block montserrat-light text-white"
									style={{ fontSize: "14px" }}
								>
									{date}
								</span>
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col className="py-7">
						<GatsbyImage
							image={
								blogPostFields.blogPostBanner.blogPostBannerImage?.node
									?.localFile.childImageSharp.gatsbyImageData
							}
							alt={
								blogPostFields.blogPostBanner.blogPostBannerImage?.node?.altText
							}
							className="border-2 border-white border rounded-5"
							style={{
								maxHeight: "600px",
								maxWidth: "100%",
							}}
						/>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export const sectionWrapper = (bgImage) => ({
	background: `linear-gradient(0deg, rgba(63, 122, 97, 0.95) 0%, rgba(63, 122, 97, 0.95) 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
});

export default BlogBanner;
