import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import BlogCard from "./blog-card";
import { GatsbyImage } from "gatsby-plugin-image";

const RelatedBlogs = ({
	heading,
	btnCta1,
	allPostList,
	backgroundColor = "#437DC0",
	currentPost,
}) => {
	const [relatedPostData, setRelatedPostData] = useState([]);
	useEffect(() => {
		const postListData = [];
		const { terms } = currentPost;
		if (terms.nodes?.length > 0) {
			let allCatList = [];
			terms.nodes.map((item) => {
				allCatList.push(item.slug);
			});

			allPostList.filter((item) => {
				if (item.terms.nodes.some((e) => allCatList.includes(e.slug))) {
					postListData.push(item);
				}
			});
			setRelatedPostData(postListData.splice(0, 3));
		}
	}, []);
	const catName = (catList) => {
		let outStr = "";
		if (catList.length > 0) {
			catList.forEach(
				(ele, idx) =>
					(outStr += `${ele.name} ${idx < catList.length ? "" : ", "}`)
			);
		}

		return outStr;
	};

	return (
		<section
			className="d-flex align-items-center py-5 py-md-8"
			style={{ backgroundColor: backgroundColor }}
		>
			<Container>
				<div
					lg={7}
					className={`d-flex justify-content-between mb-6 align-items-center`}
				>
					<h1 className="my-auto text-white">{heading}</h1>
					{btnCta1 && btnCta1.url && (
						<Button
							as={Link}
							href={btnCta1.url}
							size="lg"
							className="px-4 py-3 montserrat-bold fs-6 bg-transparent text-white border-white"
							target={btnCta1.target}
						>
							{btnCta1.title}
						</Button>
					)}
				</div>

				{relatedPostData.length > 0 && (
					<Row>
						{relatedPostData.map((item, i) => (
							<Col lg={4} key={i} className="mb-4">
								<Link
									className="text-decoration-none"
									to={`/blog/${item.slug}`}
								>
									<div className="border border-white border-2 rounded-5 h-100">
										<GatsbyImage
											image={
												item.featureImage.featureImage.node?.localFile
													.childImageSharp.gatsbyImageData
											}
											alt={item.featureImage.featureImage.node?.altText}
											className="rounded-top-5"
											style={{
												width: "100%",
												height: "300px",
											}}
										/>
										<div className="p-4">
											<span className="text-white">
												{catName(item.terms?.nodes)}
											</span>
											<h5 className="text-white fs-5 montserrat-black mb-1">
												{item.title}
											</h5>
											<div
												dangerouslySetInnerHTML={{
													__html: item.excerpt,
												}}
												className="text-white fs-6 blog-content"
											></div>
											<div className="d-flex mt-4 align-items-center">
												{/* {item.blogPostFields.blogPostBanner
												.blogPostBannerAuthorProfileImage?.node.sourceUrl ? (
												<GatsbyImage
													image={
														item.blogPostFields.blogPostBanner
															.blogPostBannerAuthorProfileImage?.node?.localFile
															.childImageSharp.gatsbyImageData
													}
													alt={
														item.blogPostFields.blogPostBanner
															.blogPostBannerAuthorProfileImage?.node?.altText
													}
													className="rounded-5"
												/>
											) : (
												<AvatarIcon style={{ width: "46px", height: "46px" }} />
											)} */}

												<div>
													{/* <span className="fs-6 text-white">
                          {
                            item.blogPostFields.blogPostBanner
                              .blogPostBannerAuthorName
                          }
                        </span> */}
													<span
														className="d-block montserrat-light text-white"
														style={{ fontSize: "14px" }}
													>
														{item.date}
													</span>
												</div>
											</div>
										</div>
									</div>
								</Link>
							</Col>
						))}
					</Row>
				)}
			</Container>
		</section>
	);
};

export default RelatedBlogs;
